import React from "react"
import { graphql } from "gatsby"
import { Article } from "../types"
import { Layout } from "../components/organisms/Layout"
import { Hero } from "../components/organisms/Hero"
import { RichText } from "../components/organisms/RichText"
import { ArticleTitle } from "../components/molecules/ArticleTitle"
import { Image } from "../components/atoms/Image"
import tw from "twin.macro"
import { SEO } from "../components/atoms/SEO"
import './article.css'

type ArticleTemplateProps = {
  data: {
    article: Article
    background: any
  }
}

const ArticleTemplate = ({ data }: ArticleTemplateProps): JSX.Element => {
  const { article } = data
  const { backgroundImage } = data?.background?.sections[0]
  const {
    title,
    slug,
    author,
    mainImage,
    mainImageAlt,
    hideMainImage,
    category,
    publishedAt,
    body,
    _rawBody,
    seo,
    seoImage,
    hidePublishedAt,
  } = article

  return (
    <Layout>
      <SEO
        title={seo?.seo_title || title}
        description={seo?.meta_description}
        imageURL={seoImage?.asset?.url}
      />
      <Hero heroType="quarterPage" backgroundImage={backgroundImage} />
      <section>
        <ArticleTitle
          author={author?.name}
          title={title}
          category={category}
          publishedAt={publishedAt}
          hidePublishedAt={hidePublishedAt}
        />
        {mainImage && !hideMainImage && (
          <Image
            alt={mainImageAlt ?? ""}
            css={[tw`h-[512px] object-cover w-full`]}
            image={mainImage}
          />
        )}
        {_rawBody && <RichText css={[tw`bg-white py-8 pt-0`]} _rawBody={_rawBody} />}
      </section>
    </Layout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query Article($id: String!) {
    background: sanityPage(title: { eq: "Resources" }) {
      sections {
        ...HeroData
      }
    }
    article: sanityArticle(id: { eq: $id }) {
      _rawBody(resolveReferences: { maxDepth: 4 })
      title
      slug {
        current
      }
      seo {
        _type
        meta_description
        seo_title
      }
      seoImage {
        asset {
          url
        }
      }
      author {
        name
        slug {
          current
        }
        image {
          ...ImageWithPreview
        }
        # bio
      }
      mainImage {
        ...ImageWithPreview
      }
      mainImageAlt
      hideMainImage
      category {
        title
      }
      publishedAt
      hidePublishedAt
      # body
    }
  }
`
