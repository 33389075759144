import React from "react"
import tw from "twin.macro"
import { NewsCategory } from "../NewsCategory"
import { ArticleTitleProps } from "./ArticleTitle.d"

const ArticleTitle: React.FC<ArticleTitleProps> = ({
  author,
  publishedAt,
  hidePublishedAt = false,
  title,
  category,
}) => {

  return (
    <div css={[tw`pb-0 p-8 max-w-5xl mx-auto`]}>
      <p css={[
        tw`font-karla font-extralight uppercase tracking-[2px]`,
          hidePublishedAt && tw`hidden`
        ]}>
        {new Date(publishedAt).toLocaleDateString("en-US", {
          month: "long",
          day: "2-digit",
          year: "numeric",
        })}
      </p>
      <h1
        css={[
          tw`font-crimson-pro text-rnti-h1-article-mobile md:text-rnti-h1-article font-extralight mt-2 text-black`,
        ]}
      >
        {title}
      </h1>
      <NewsCategory
        css={[tw`mt-2`]}
        category={category?.title}
        author={author}
      />
    </div>
  )
}

export default ArticleTitle
